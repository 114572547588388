import { combineReducers } from '@reduxjs/toolkit';
import userMgmtReducer from './Slices/userMgmt'
import staticMgmtReducer from './Slices/staticMgmt';
import serviceMgmtReducer from './Slices/serviceMgmt';
import blogsMgmtReducer from './Slices/blogsMgmt';
import bannerMgmtReducer from './Slices/bannerMgmt';
import astrologerMgmtReducer from './Slices/astrologerMgmt';
import supportMgmtReducer from './Slices/supportMgmt';
import faqMgmtReducer from './Slices/faqMgmt';
import accountReducer from "./Slices/Login/login";
import subAdminMgmtReducer from './Slices/subAdminMgmt';






const rootReducer = combineReducers({
    userMgmt: userMgmtReducer,
    staticMgmt: staticMgmtReducer,
    serviceMgmt: serviceMgmtReducer,
    blogsMgmt: blogsMgmtReducer,
    bannerMgmt: bannerMgmtReducer,
    astrologerMgmt: astrologerMgmtReducer,
    supportMgmt: supportMgmtReducer,
    faqMgmt: faqMgmtReducer,
    accountMgmt: accountReducer,
    subAdminMgmt: subAdminMgmtReducer,
})

export default rootReducer