import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import { useDispatch, useSelector } from 'react-redux'
import { addBlogs, categoryList } from '../../Redux/Slices/blogsMgmt';
import S3FileUpload from 'react-s3/lib/ReactS3'
import { toast } from 'react-toastify';
import { commomObj } from '../../Utils/util';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { credAndUrl } from '../../Config/config';


const initialState = {
    name: '',
    categoryId: '',
    authorName: '',
    image: '',
    content: '',
    blogId: '',
    errors: "",
    flag: false

}

const AddBlogs = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { item, heading } = location?.state;
    const [iState, updateState] = useState(initialState);
    const { name, categoryId, authorName, image, content, blogId, errors } = iState;
    const { getCategoryList, loader } = useSelector(state => state.blogsMgmt);



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        updateState({ ...iState, [name]: value, errors: "" });
    }

    const config = {
       bucketName: credAndUrl?.BUCKET_NAME,
        dirName: credAndUrl?.DIR_NAME,
        region: credAndUrl?.REGION,
        accessKeyId: credAndUrl?.ACCESS_KEY_ID,
        secretAccessKey: credAndUrl?.SECRET_ACCESS_KEY,
    };

    window.Buffer = window.Buffer || require("buffer").Buffer;


    const onImageHandler = async (e) => {
        const file = e.target.files[0];
        console.log("filetype in img", file?.type, file);
        if
            (
                file?.type === "image/jpeg" ||
                file?.type === "image/jpg" ||
                file?.type === "image/png"
        ) {
            if (file.size * 0.000001 <= 5) {
                const data = await S3FileUpload.uploadFile(file, config)
                updateState({ ...iState, image: data?.location });
                // e.target.value = "";
            }
            else {
                toast.error("Image should be below 5MB", commomObj);
            }
        } else {
            toast.error("*Please upload in JPEG,PNG,JPG format Only", commomObj);

        }
    }

    const handleImageClose = () => {
        updateState({ ...iState, image: '' })
    }

    const handleValidation = () => {
        let error = {};
        let formIsValid = true;

        if (!name) {
            error.nameError = " *Name is mandetory";
            formIsValid = false;
        }
        if (!authorName) {
            error.authorNameError = " *Author Name is mandetory";
            formIsValid = false;
        }
        if (!image) {
            error.imageError = " *image is mandetory";
            formIsValid = false;
        }
        if (!content) {
            error.contentError = " *Content is mandetory";
            formIsValid = false;
        }
        if (!categoryId) {
            error.categoryIdError = " *Select any category";
            formIsValid = false;
        }
        updateState({ ...iState, errors: error });
        return formIsValid;
    }

    const handlePublish = () => {
        let formIsValid = handleValidation();
        if (formIsValid) {
            const data = {
                name,
                authorName,
                image,
                content,
                categoryId,
                blogId,
                heading,
            }

            dispatch(addBlogs(data))
                .then((result) => {
                    console.log({ result })
                    if (result?.payload?.status == 200) {
                        toast.success(result?.payload?.message, commomObj);
                        navigate("/blogs-management")
                    }

                }).catch((err) => {
                    console.log({ err })

                });
        }
    }


    console.log({ image })
    useEffect(() => {
        dispatch(categoryList());
    }, [])

    useEffect(() => {
        if (item) {
            updateState({
                ...iState,
                name: item?.name,
                authorName: item?.authorName,
                categoryId: item?.categoryId,
                content: item?.content,
                image: item?.image,
                blogId: item?._id,
                flag: true
            })
        }
    }, [item])

    console.log({ item });
    return (
        <>
            <Header heading={heading} />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title"></h4>
                        <div className="BackArrow m-0">
                            <Link to="/blogs-management" state="Blogs Management">
                                <i className="fa fa-long-arrow-left" /> Back
                            </Link>
                        </div>
                    </div>
                    <div className="Small-Wrapper">
                        <div className="CommonForm">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Title Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Title Name"
                                            name='name'
                                            value={name}
                                            onChange={handleInputChange}
                                        />
                                        <span className='error'>{errors?.nameError}</span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Select Category</label>
                                        <select
                                            className="form-control"
                                            name='categoryId'
                                            onChange={handleInputChange}
                                        >
                                            <option selected="">--select--</option>
                                            {getCategoryList?.data?.result?.length > 0 ?
                                                getCategoryList?.data?.result?.map((item, i) => {
                                                    return (
                                                        <option value={item?._id} selected={categoryId == item?._id}>{item?.name}</option>
                                                    )
                                                })
                                                : ''}
                                        </select>
                                        <span className='error'>{errors?.categoryIdError}</span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Author Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Author Name"
                                            name='authorName'
                                            value={authorName}
                                            onChange={handleInputChange}
                                        />
                                        <span className='error'>{errors?.authorNameError}</span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Blog Image</label>
                                        {/* <input
                                            type="file"
                                            className="form-control"
                                            onChange={onImageHandler} /> */}
                                        <div class="UploadBox">
                                            <div class="Upload" style={{ borderRadius: "5px", padding: '10px 10px 10px 15px' }} >
                                                <span> <i class="fa-solid fa-upload"></i> Upload Service Icon</span>
                                                <input type="file" onChange={onImageHandler} />
                                            </div>
                                        </div>
                                        <span className='error'>{errors?.imageError}</span>
                                    </div>
                                </div>
                                <div className="col-md-6"></div>
                                <div className="col-md-6"> {image ?
                                    <div className="preview-img" style={{ top: "0px" }}>
                                        <span className="cross-btn" onClick={handleImageClose}>×</span>
                                        <img src={image} style={{ borderRadius: "10%" }} />
                                    </div>
                                    : ""}</div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Blog Content</label>
                                        {/* <textarea
                                            className="form-control"
                                            rows={5}
                                            placeholder="Write here.."
                                            id="editor"
                                            defaultValue={""}
                                            name='content'
                                            value={content}
                                            onChange={handleInputChange}
                                        /> */}
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={content || ""}
                                            onReady={editor => {
                                                console.log('Editor is ready to use!', editor);
                                            }}
                                            onChange={(event, editor) => {
                                                const data = editor?.getData();
                                                updateState({ ...iState, content: data, errors: "" });
                                                // console.log({ event, editor, data });
                                            }}
                                            onBlur={(event, editor) => {
                                                console.log('Blur.', editor);
                                            }}
                                            onFocus={(event, editor) => {
                                                console.log('Focus.', editor);
                                            }} key={iState?.flag} />
                                        <span className='error'>{errors?.contentError}</span>
                                    </div>
                                </div>
                            </div>
                            <button className="Button" onClick={handlePublish}> {heading == "Clone Blog" ? "Clone" : heading == "Edit Blog" ? "Save Changes" : "Publish"}</button>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}

export default AddBlogs
