import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, updateProfile } from '../../Redux/Slices/Login/login';
import S3FileUpload from 'react-s3/lib/ReactS3';
import { toast } from 'react-toastify';
import { commomObj } from '../../Utils/util';
import { credAndUrl } from '../../Config/config';



const initialState = {
    image: '',
    address: '',
    fullName: "",
    contact: '',
    email: '',
    errors: ''

}

const MyAccount = () => {


    const [iState, updateState] = useState(initialState);
    const { image, fullName, email, address, contact, errors } = iState;


    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const heading = location?.state;


    const { getProfileDetail } = useSelector(state => state.accountMgmt);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name == 'contact') {
            let modifiedValue = value >= 0 ? value : contact?.length > 1 ? contact + "" : "";
            updateState({ ...iState, [name]: modifiedValue, errors: '' });
        } else {
            updateState({ ...iState, [name]: value, errors: '' });
        }
    }



    const config = {
        bucketName: credAndUrl?.BUCKET_NAME,
        dirName: credAndUrl?.DIR_NAME,
        region: credAndUrl?.REGION,
        accessKeyId: credAndUrl?.ACCESS_KEY_ID,
        secretAccessKey: credAndUrl?.SECRET_ACCESS_KEY,
    };

    window.Buffer = window.Buffer || require("buffer").Buffer;

    const onImageHandler = async (e) => {
        const file = e?.target?.files[0];
        console.log("filetype in img", file?.type, file);
        if
            (
            file?.type === "image/jpeg" ||
            file?.type === "image/jpg" ||
            file?.type === "image/png"
        ) {
            if (file?.size * 0.000001 <= 5) {
                const data = await S3FileUpload.uploadFile(file, config)
                updateState({ ...iState, image: data?.location });
                // e.target.value = "";
            }
            else {
                toast.error("Image should be below 5MB", commomObj);
            }
        } else {
            toast.error("*Please upload in JPEG,PNG,JPG format Only", commomObj);

        }
    };




    const handleValidation = () => {
        let error = {};
        let formIsValid = true;

        // if (!image) {
        //     error.imageError = " *Image is mandetory";
        //     formIsValid = false;
        // }
        if (!fullName) {
            error.fullNameError = " *Name is mandetory";
            formIsValid = false;
        }
        if (!email) {
            error.emailError = " *Email is mandetory";
            formIsValid = false;
        }
        if (!address) {
            error.addressError = " *Address is mandetory";
            formIsValid = false;
        }
        if (!contact) {
            error.contactError = " *Contact is mandetory";
            formIsValid = false;
        }
        updateState({ ...iState, errors: error });
        return formIsValid;
    }

    const handleSaveDetail = () => {
        let formIsValid = handleValidation();
        if (formIsValid) {
            const data = {
                image,
                fullName,
                email,
                address,
                contact
            }

            dispatch(updateProfile(data))
                .then((result) => {
                    console.log({ result })
                    if (result?.payload?.status == 200) {
                        toast.success(result?.payload?.message, commomObj);
                        navigate('/')
                    }

                }).catch((err) => {
                    console.log({ err })
                });
        }
    }

    useEffect(() => {
        dispatch(getProfile())
    }, [])


    useEffect(() => {
        if (getProfileDetail) {
            updateState({
                ...iState,
                image: getProfileDetail?.data?.image,
                fullName: getProfileDetail?.data?.fullName,
                email: getProfileDetail?.data?.email,
                address: getProfileDetail?.data?.address,
                contact: getProfileDetail?.data?.contact,

            })

        }

    }, [getProfileDetail])


    console.log({ getProfileDetail })
    return (
        <>
            <Header heading={heading} />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        {/* <h4 className="Title"></h4> */}
                    </div>
                    <div className="Small-Wrapper">
                        <div className="CommonForm">
                            <fieldset disabled="">
                                <form>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="AccountProfile">
                                                <figure>
                                                    <span>
                                                        <img src={image ? image : require("../../Assets/images/Avatar-1.png")} />{" "}
                                                    </span>
                                                    <div className="UploadOverlay">
                                                        <input type="file" onChange={onImageHandler} />
                                                        <img src={require("../../Assets/images/camera.png")} />

                                                    </div>

                                                </figure>
                                            </div>
                                            <div className="form-group">
                                                <label>Full Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    name='fullName'
                                                    value={fullName}
                                                    onChange={handleInputChange}
                                                />
                                                <span className='error'>{errors?.fullNameError}</span>
                                            </div>
                                            <div className="form-group">
                                                <label>Email Address</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    name='email'
                                                    value={email}
                                                    onChange={handleInputChange}
                                                />
                                                <span className='error'>{errors?.emailError}</span>
                                            </div>
                                            <div className="form-group">
                                                <label>Contact Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    name='contact'
                                                    value={contact}
                                                    onChange={handleInputChange}
                                                />
                                                <span className='error'>{errors?.contactError}</span>
                                            </div>
                                            <div className="form-group">
                                                <label>Address</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    name='address'
                                                    value={address}
                                                    onChange={handleInputChange}
                                                />
                                                <span className='error'>{errors?.addressError}</span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </fieldset>
                            <button className="Button" onClick={handleSaveDetail}>Save Details</button>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default MyAccount
