import React from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import { Link, useLocation } from 'react-router-dom';

const SubAdminDetail = () => {


    const location = useLocation();
    const { heading, item } = location?.state;



    const arrName = ['Dashboard', 'User Management', 'Astrologer Management', 'Astrologer Management / New Registration', 'Astrologer Management / Approved Astrologers', 'Appointment Management', 'Service Management', 'Payment Management', 'Payment Management / Transaction Details', 'Payment Management / Revenue Details', 'Support Management', 'Blogs Management', 'Sub-Admin Management', 'Banner Management', 'Notification Management', 'Static Content Management', 'My Account']


    console.log({ item })

    return (
        <>
            <Header heading={heading} />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title"></h4>
                        <div className="BackArrow m-0">
                            <Link to="/subadmin-management" state="Sub Admin Management">
                                <i className="fa fa-long-arrow-left" /> Back
                            </Link>
                        </div>
                    </div>
                    <div className="Small-Wrapper">
                        <div className="DetailsArea" style={{ display: "block" }}>
                            <div className="DetailsContent">
                                <aside>
                                    <p>
                                        <label>Sub Admin ID</label>
                                        <span>{item?.subAdminId}</span>
                                    </p>
                                    <p>
                                        <label>Sub Admin Name</label>
                                        <span>{item?.fullName}</span>
                                    </p>
                                    <p>
                                        <label>Contact Number</label>
                                        <span>{item?.contact}</span>
                                    </p>
                                    <p>
                                        <label>Email ID</label>
                                        <span>{item?.email}</span>
                                    </p>
                                </aside>
                                <aside>
                                    <p>
                                        <label>Registered Date</label>
                                        <span>{item?.createdAt?.split("T")?.at(0)}</span>
                                    </p>
                                    <p>
                                        <label>Role</label>
                                        <span>{item?.roleName}</span>
                                    </p>
                                    <p>
                                        <label>Status</label>
                                        <span>{item?.isActive ? "Active" : "Inactive"}</span>
                                    </p>
                                </aside>
                            </div>
                        </div>
                        <div className="CommonForm mt-4">
                            <h4>Sub Admin Rights Access</h4>
                            <fieldset disabled="">
                                <div className="AdminAccess">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Module Name</th>
                                                <th width="150px">Read</th>
                                                <th width="150px">Full Access</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {arrName?.map((module, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            <strong>{module}</strong>
                                                        </td>
                                                        <td>
                                                            <label className="CheckBox">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={item?.subAdminRightAccess?.some((item) => (item.name == module && item.isRead == true))}
                                                                    disabled
                                                                />
                                                                <span className="checkmark" />
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <label className="CheckBox">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={item?.subAdminRightAccess?.some((item) => (item.name == module && item.fullaccess == true))}
                                                                    disabled
                                                                />
                                                                <span className="checkmark" />
                                                            </label>
                                                        </td>
                                                    </tr>
                                                )
                                            })}

                                        </tbody>
                                    </table>
                                </div>
                            </fieldset>
                        </div>
                    </div>

                </div>
            </div>


        </>
    )
}

export default SubAdminDetail
