import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addSubAdmin } from '../../Redux/Slices/subAdminMgmt';
import { toast } from 'react-toastify';
import { commomObj } from '../../Utils/util';




const initialState = {
    id: '',
    fullName: '',
    roleName: '',
    email: '',
    contact: '',
    subAdminRightAccess: [],
    errors: '',
}

const AddSubAdmin = () => {



    const [iState, updateState] = useState(initialState);
    const { id, fullName, roleName, email, contact, subAdminRightAccess, errors } = iState;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { heading, item } = location?.state;




    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name == "contact") {
            let modifiedValue = value > 0 ? value : contact?.length !== 1 ? contact + "" : '';
            updateState({ ...iState, contact: modifiedValue, errors: "" });
        } else {
            updateState({ ...iState, [name]: value, errors: "" });
        }

    }


    const arrName = ['Dashboard', 'User Management', 'Astrologer Management', 'Astrologer Management / New Registration', 'Astrologer Management / Approved Astrologers', 'Appointment Management', 'Service Management', 'Payment Management', 'Payment Management / Transaction Details', 'Payment Management / Revenue Details', 'Support Management', 'Blogs Management', 'Sub-Admin Management', 'Banner Management', 'Notification Management', 'Static Content Management', 'My Account']

    let arrRead = ['Read', 'DashboardRead', 'User ManagementRead', 'Astrologer ManagementRead', 'Astrologer Management / New RegistrationRead', 'Astrologer Management / Approved AstrologersRead', 'Appointment ManagementRead', 'Service ManagementRead', 'Payment ManagementRead', 'Payment Management / Transaction DetailsRead', 'Payment Management / Revenue DetailsRead', 'Support ManagementRead', 'Blogs ManagementRead', 'Banner ManagementRead', 'Notification ManagementRead', 'Static Content ManagementRead', 'My AccountRead']

    let arrAccess = ['Access', 'DashboardAccess', 'User ManagementAccess', 'Astrologer ManagementAccess', 'Astrologer Management / New RegistrationAccess', 'Astrologer Management / Approved AstrologersAccess', 'Appointment ManagementAccess', 'Service ManagementAccess', 'Payment ManagementAccess', 'Payment Management / Transaction DetailsAccess', 'Payment Management / Revenue DetailsAccess', 'Support ManagementAccess', 'Blogs ManagementAccess', 'Banner ManagementAccess', 'Notification ManagementAccess', 'Static Content ManagementAccess', 'My AccountAccess']


    const handleCheckBox = (e, type) => {
        const { name, checked } = e.target
        console.log({ name })
        arrRead.map((item) => {
            if (item == name) {
                if (checked) {
                    if (subAdminRightAccess?.some(item => item.name == type)) {
                        updateState({
                            ...iState, errors: '', subAdminRightAccess: subAdminRightAccess?.map((item) => {
                                if (item.name == type) {
                                    item.isRead = true;
                                    item.fullaccess = false;
                                }
                                return item
                            })
                        })
                    } else {
                        updateState({
                            ...iState, errors: '',
                            subAdminRightAccess: [...subAdminRightAccess, { name: type, isRead: true, fullaccess: false }],
                        })
                    }
                }
                else {
                    updateState({
                        ...iState, errors: '',
                        subAdminRightAccess: subAdminRightAccess?.filter((item) => (item.name !== type)),
                    })

                }
            }
        })
        arrAccess.map((item) => {
            if (item == name) {
                if (checked) {
                    if (subAdminRightAccess?.some(item => item.name == type)) {
                        updateState({
                            ...iState, errors: '', subAdminRightAccess: subAdminRightAccess?.map((item) => {
                                if (item.name == type) {
                                    item.isRead = false;
                                    item.fullaccess = true;
                                }
                                return item
                            })
                        })
                    } else {
                        updateState({
                            ...iState, errors: '',
                            subAdminRightAccess: [...subAdminRightAccess, { name: type, isRead: false, fullaccess: true }],
                        })
                    }
                }
                else {
                    updateState({
                        ...iState, errors: '',
                        subAdminRightAccess: subAdminRightAccess?.filter((item) => (item.name !== type)),

                    })

                }
            }
        })
    }


    const handleValidation = () => {
        let error = {};
        let formIsValid = true;

        const section = document.getElementById('subAdminDetails');


        if (!fullName) {
            error.fullNameError = "*Name is mandetory";
            formIsValid = false;
        }
        if (!email) {
            error.emailError = "*Email is mandetory";
            formIsValid = false;
        }
        if (!contact) {
            error.contactError = "*Contact is mandetory";
            formIsValid = false;
        }
        if (!roleName) {
            error.roleNameError = "*Role Name is mandetory";
            formIsValid = false;
        }
        if (subAdminRightAccess?.length == 0) {
            error.subAdminRightAccessError = "*Please select modules";
            formIsValid = false;
        }

        if (!fullName || !email || !roleName || !contact) {
            section.scrollIntoView({ behavior: "smooth" });
        }
        updateState({ ...iState, errors: error });
        return formIsValid;
    }

    const handleAdd = () => {
        let formIsValid = handleValidation();
        if (formIsValid) {
            const data = {
                id,
                fullName,
                email,
                contact,
                roleName,
                subAdminRightAccess
            }
            dispatch(addSubAdmin(data))
                .then((result) => {
                    console.log({ result })
                    if (result?.payload?.status == 200) {
                        toast.success(result?.payload?.message, commomObj);
                        navigate("/subadmin-management", { state: 'Sub-Admin Management' })
                    }
                    else {
                        toast.error(result?.payload?.message, commomObj);
                    }

                }).catch((err) => {
                    console.log({ err })
                });
        }

    }


    useEffect(() => {
        if (item) {
            updateState({
                ...iState,
                id: item?._id,
                fullName: item?.fullName,
                email: item?.fullName,
                contact: item?.contact,
                roleName: item?.roleName,
                subAdminRightAccess: item?.subAdminRightAccess
            })
        }
    }, [item])


    console.log({ iState, item })



    return (
        <>
            <Header heading={heading} />
            <SideNav />
            <div className="WrapperArea" id='subAdminDetails'>
                <div className="WrapperBox">
                    <div className="TitleBox" >
                        <h4 className="Title"></h4>
                        <div className="BackArrow m-0">
                            <Link to="/subadmin-management" state="Sub Admin Management">
                                <i className="fa fa-long-arrow-left" /> Back
                            </Link>
                        </div>
                    </div>
                    <div className="Small-Wrapper">
                        <div className="CommonForm">
                            <div className="row" >
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Sub-Admin Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Sub Admin Name"
                                            name='fullName'
                                            value={fullName}
                                            onChange={handleInputChange}
                                        />
                                        <span className='error'>{errors?.fullNameError}</span>
                                    </div>
                                    <div className="form-group">
                                        <label>Phone Number</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Phone Number"
                                            name='contact'
                                            value={contact}
                                            onChange={handleInputChange}
                                        />
                                        <span className='error'>{errors?.contactError}</span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Email ID</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Email ID"
                                            name='email'
                                            value={email}
                                            onChange={handleInputChange}
                                        />
                                        <span className='error'>{errors?.emailError}</span>
                                    </div>
                                    <div className="form-group">
                                        <label>Role</label>
                                        <select className="form-control" name='roleName' onChange={handleInputChange} >
                                            <option value="" selected={roleName == ""}>--Select Role--</option>
                                            <option value="Manager" selected={roleName == "Manager"}>Manager</option>
                                        </select>
                                        <span className='error'>{errors?.roleNameError}</span>
                                    </div>
                                </div>
                            </div>
                            <h4>Sub Admin Rights Access</h4>
                            <div className="AdminAccess">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Module Name</th>
                                            <th width="150px">Read</th>
                                            <th width="150px">Full Access</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {arrName?.map((module, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <strong>{module}</strong>
                                                    </td>
                                                    <td>
                                                        <label className="CheckBox">
                                                            <input
                                                                type="checkbox"
                                                                name='Read'
                                                                checked={subAdminRightAccess?.some((item) => (item.name == module && item.isRead == true))}
                                                                onChange={(e) => handleCheckBox(e, module)}
                                                            />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <label className="CheckBox">
                                                            <input
                                                                type="checkbox"
                                                                name='Access'
                                                                onChange={(e) => handleCheckBox(e, module)}
                                                                checked={subAdminRightAccess?.some((item) => (item.name == module && item.fullaccess == true))}

                                                            />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <span className='error'>{errors?.subAdminRightAccessError}</span>
                            </div>
                            <button className="Button" onClick={handleAdd}>{id ? "Save" : "Add"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddSubAdmin
