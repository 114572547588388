import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Modal, Nav, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addFaq, faqList } from '../../Redux/Slices/faqMgmt';
import { toast } from 'react-toastify';
import { commomObj, getPathName } from '../../Utils/util';
import StatusModal from '../Modals/StatusModal';


const initialState = {
    addModal: false,
    type: '',
    faqName: "",
    description: '',
    statusModal: false,
    status: '',
    id: '',
    title: 'Faq',
    type1: '',
    faqId: '',
    modalType: '',
    errors: ''

}

const FaqMgmt = () => {


    const [iState, updateState] = useState(initialState);
    const { addModal, faqName, description, type1, type, id, faqId, modalType, errors } = iState;



    const { getFaqList, loader } = useSelector(state => state.faqMgmt);
    const location = useLocation();
    const heading = location?.state;
    const { pathType } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pathname = getPathName();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        updateState({ ...iState, [name]: value, errors: '' });
    }


    const handleStatusShow = (item, type) => {
        updateState({ ...iState, statusModal: true, status: "delete", id: item?._id, type })
    }

    const AddModalHideShow = (type) => {
        if (type == "hide") {
            updateState(initialState);
        }
        else if (type == "show") {
            updateState({ ...iState, addModal: true })
        }
    }

    const EditModalHideShow = (item) => {
        updateState({
            ...iState,
            addModal: true,
            modalType: "Edit",
            faqName: item?.faqName,
            faqId: item?._id,
            type1: item?.type,
            description: item?.description
        })
    }

    const handleValidation = () => {
        let error = {};
        let formIsValid = true;

        if (!faqName) {
            error.faqNameError = " *This field is mandetory";
            formIsValid = false;
        }
        if (!description) {
            error.descriptionError = " *This field is mandetory";
            formIsValid = false;
        }
        if (!type1) {
            error.type1Error = " *Please select one";
            formIsValid = false;
        }
        updateState({ ...iState, errors: error });
        return formIsValid;
    }

    const handleAdd = () => {
        let formIsValid = handleValidation();
        if (formIsValid) {
            const data = {
                faqId,
                faqName,
                type: type1,
                description
            }
            dispatch(addFaq(data))
                .then((result) => {
                    console.log({ result })
                    if (result?.payload?.status == 200) {
                        toast.success(result?.payload?.message, commomObj);
                        updateState(initialState);
                        dispatch(faqList({ type: pathname }))
                    }

                }).catch((err) => {
                    console.log(err)
                });
        }
    }



    useEffect(() => {
        dispatch(faqList({ type: pathname }))
            .then((result) => {
                console.log({ result })
                if (result?.payload?.status == 401) {
                    toast.error("Invalid Token.Please Login again", commomObj);
                    navigate('/');
                }
            }).catch((err) => {
                console.log({ err })
            });
    }, [pathname])

    console.log({ getFaqList, pathname })
    return (
        <>
            <Header heading={heading} />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title"></h4>
                    </div>
                    <Tab.Container defaultActiveKey={pathType}>
                        <div className="CommonTabs">
                            <Nav className='nav-tabs'>
                                <Nav.Item >
                                    <Nav.Link eventKey="user" as={Link} to='/faq-management/user' state="FAQ Management"
                                    >  FAQ (End-User){" "}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="astrologer" as={Link} to='/faq-management/astrologer' state="FAQ Management"> FAQ (Astrologer)</Nav.Link>
                                </Nav.Item>

                            </Nav>
                            <Tab.Content className="tab-content">
                                <div
                                    className="TitleBox justify-content-end"
                                    style={{ marginTop: "-45px" }}
                                >
                                    <a
                                        className="TitleLink"
                                        href="javascript:void(0);"
                                        data-toggle="modal"
                                        onClick={() => AddModalHideShow("show")}
                                    >
                                        {" "}
                                        Add Faq{" "}
                                    </a>
                                </div>
                                <Tab.Pane className="tab-pane" eventKey='user'>
                                    <div className="StaticArea">

                                        {getFaqList?.data?.length > 0 ?
                                            getFaqList?.data?.map((item, i) => {
                                                return (
                                                    <div className="card">
                                                        <div
                                                            className="card-header"
                                                            data-toggle="collapse"
                                                            data-parent="#accordion"
                                                            href={`#collapse${i}`}
                                                        >
                                                            <h4>{item?.faqName}</h4>
                                                            <aside>
                                                                <a
                                                                    href="javascript:void(0);"
                                                                    className="Green"
                                                                    data-toggle="modal"
                                                                    onClick={() => EditModalHideShow(item)}
                                                                >
                                                                    <i className="fa fa-pencil" />
                                                                </a>
                                                                {"           "}
                                                                <a
                                                                    href="javascript:void(0);"
                                                                    className="Red"
                                                                    data-toggle="modal"
                                                                    onClick={() => handleStatusShow(item, "faq/delete-faq")}
                                                                >
                                                                    <i className="fa fa-trash" />
                                                                </a>
                                                            </aside>
                                                        </div>
                                                        <div id={`collapse${i}`} className="panel-collapse collapse">
                                                            <div className="card-body">
                                                                <p>{item?.description}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : ''}


                                    </div>
                                </Tab.Pane>
                                <Tab.Pane className="tab-pane" eventKey='astrologer'>
                                    <div className="StaticArea">
                                        <div id="accordion">
                                            {getFaqList?.data?.length > 0 ?
                                                getFaqList?.data?.map((item, i) => {
                                                    return (
                                                        <div className="card">
                                                            <div
                                                                className="card-header"
                                                                data-toggle="collapse"
                                                                data-parent="#accordion"
                                                                href={`#collapse${i}`}
                                                            >
                                                                <h4>{item?.faqName}</h4>
                                                                <aside>
                                                                    <a
                                                                        href="javascript:void(0);"
                                                                        className="Green"
                                                                        data-toggle="modal"
                                                                        onClick={() => EditModalHideShow(item)}
                                                                    >
                                                                        <i className="fa fa-pencil" />
                                                                    </a>
                                                                    {"           "}
                                                                    <a
                                                                        href="javascript:void(0);"
                                                                        className="Red"
                                                                        data-toggle="modal"
                                                                        onClick={() => handleStatusShow(item, "faq/delete-faq")}
                                                                    >
                                                                        <i className="fa fa-trash" />
                                                                    </a>
                                                                </aside>
                                                            </div>
                                                            <div id={`collapse${i}`} className="panel-collapse collapse">
                                                                <div className="card-body">
                                                                    <p>{item?.description}</p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                : ''}

                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </Tab.Container>
                </div>
            </div>
            <Modal
                show={addModal}
                onHide={() => AddModalHideShow("hide")}
                className="ModalBox"
            >
                <Modal.Body>
                    <div className="Category">
                        <a href="javascript:void(0);" className="CloseModal" data-dismiss="modal">
                            ×
                        </a>
                        <h3>{modalType ? "Edit" : "Add"} Faq</h3>
                        <div className="form-group">
                            <label>Faq Question</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Faq Question"
                                name='faqName'
                                value={faqName}
                                onChange={handleInputChange}
                            />
                            <span className='error'>{errors?.faqNameError}</span>
                        </div>
                        <div className="form-group">
                            <label>Faq Answer</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Faq Answer"
                                name='description'
                                value={description}
                                onChange={handleInputChange}
                            />
                            <span className='error'>{errors?.descriptionError}</span>
                        </div>
                        <div className="form-group">
                            <label>For Edit</label>
                            <select className="form-control" name='type1' onClick={handleInputChange} >
                                <option selected={type1 == ""} value="">--select--</option>
                                <option selected={type1 == "user"} value="user">End user</option>
                                <option selected={type1 == "astrologer"} value="astrologer">Astrologer</option>
                            </select>
                            <span className='error'>{errors?.type1Error}</span>
                        </div>
                        <div className="Buttons">
                            <button className="Button ml-0" onClick={handleAdd}>{modalType ? "Save" : "Add"} Faq</button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>

            <StatusModal iState={iState} updateState={updateState} type={type} />
        </>
    )
}

export default FaqMgmt
