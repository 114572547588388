import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import { useDispatch, useSelector } from 'react-redux'
import { serviceList } from '../../Redux/Slices/serviceMgmt'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'
import Pagination from 'react-js-pagination'
import StatusModal from '../Modals/StatusModal'
import { toast } from 'react-toastify'
import { commomObj } from '../../Utils/util'

const initialState = {
    statusModal: false,
    id: '',
    status: '',
    type: '',
    title: 'service',
    search: '',
    fromDate: '',
    toDate: '',
    timeFrame: '',
    error: '',
}

const ServiceMgmt = () => {

    const [iState, updateState] = useState(initialState);
    const { id, type, search, fromDate, toDate, timeFrame, error } = iState;
    const { getServiceList, loader } = useSelector(state => state.serviceMgmt);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const heading = location?.state;
    const [activePage, updateActivePage] = useState(1);



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        updateState({ ...iState, [name]: value, error: '' });
    }

    const handleRefresh = () => {
        updateState(initialState);
        dispatch(serviceList());
    }


    const handleFilterApply = () => {
        if (fromDate == '' && toDate !== '') {
            updateState({ ...iState, error: '*Please select From Date' })
        }
        else if (fromDate !== '' && toDate == '') {
            updateState({ ...iState, error: '*Please select To Date' })
        }
        else if (fromDate == '' && toDate == '' && search?.trim() == '') {
            updateState({ ...iState, error: '*Please select atleast one filter' })
        }
        else {
            const data = {
                search,
                startDate: fromDate,
                endDate: toDate,
            }
            dispatch(serviceList(data))
                .then(res => {
                    console.log("res", res)
                })
                .catch(err => {
                    console.log("err", err)
                })
        }
    }


    useEffect(() => {
        dispatch(serviceList())
            .then((result) => {
                if (result?.payload?.status == 401) {
                    toast.error("Invalid Token.Please Login again", commomObj);
                    navigate('/');
                }
            }).catch((err) => {

            });
    }, [])


    const handlePageChange = (pageNumber) => {
        updateActivePage(pageNumber);
        const data = {
            page: pageNumber,
            search,
            startDate: fromDate,
            endDate: toDate,
            timeFrame
        }
        dispatch(serviceList(data))
    };

    const handleStatusShow = (item, type) => {
        if (type == "service/status-update") {
            updateState({ ...iState, statusModal: true, status: item?.isActive, id: item?._id, type })
        }
        else if (type == "service/delete-service") {
            updateState({ ...iState, statusModal: true, status: "delete", id: item?._id, type })
        }
    }


    useEffect(() => {
        // if (timeFrame) {
            const data = {
                search,
                startDate: fromDate,
                endDate: toDate,
                timeFrame,
            }
            dispatch(serviceList(data));
        // }

    }, [timeFrame])

    console.log({ getServiceList });
    return (
        <>
            <Header heading={heading} />
            <SideNav />
            <>
                <div className="WrapperArea">
                    <div className="WrapperBox">
                        <div className="TitleBox">
                            <h4 className="Title"></h4>
                            <div>

                                <Link className="TitleLink" to="/service-management/add-service" style={{ marginRight: "5px" }} state={{ heading: "Add New Service" }}>
                                    Add New Service
                                </Link>
                                <a className="TitleLink" href="javascript:void(0);">
                                    <i className="fa fa-download" /> Download Excel
                                </a>

                            </div>
                        </div>
                        <div className="Small-Wrapper">
                            <div className="FilterArea">
                                <div className="FilterLeft">
                                    <div className="form-group">
                                        <label>Search</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter here..."
                                            name='search'
                                            value={search}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>From Date</label>
                                        <input type="date" name='fromDate' value={fromDate} onChange={handleInputChange} className="form-control" />

                                    </div>
                                    <div className="form-group">
                                        <label>To Date</label>
                                        <input type="date" name='toDate' value={toDate} onChange={handleInputChange} className="form-control" min={fromDate} />
                                    </div>
                                    <div className="form-group">
                                        <label>&nbsp;</label>
                                        <button className="Button" onClick={handleFilterApply}>Apply</button>

                                        <button className="Button Cancel" onClick={handleRefresh} style={{ marginLeft: "6px" }}>
                                            <i className="fa fa-refresh" />
                                        </button>
                                    </div>
                                </div>
                                <div className="FilterRight">
                                    <div className="form-group">
                                        <label>Timeframe</label>
                                        <select className="form-control" name='timeFrame' onClick={handleInputChange}>
                                            <option value='' selected={timeFrame == ""}>Select </option>
                                            <option value="Today">Today</option>
                                            <option value="Week">This Week</option>
                                            <option value="Month">This Month</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <span className='error'>{error}</span>
                        </div>
                        <div className="TableList">
                            <table style={{ width: "150%" }}>
                                <thead>
                                    <tr>
                                        <th>Sr. No</th>
                                        <th>Service ID</th>
                                        <th>
                                            Service <br /> Name
                                        </th>
                                        <th>Price for 30 min</th>
                                        <th>Price for 45 min</th>
                                        <th>Price for 60 min</th>
                                        <th>
                                            No. of Astrologers <br /> Providing Service
                                        </th>
                                        <th>
                                            Total Completed <br /> Appointments{" "}
                                        </th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getServiceList?.data?.result?.length > 0 ?
                                        getServiceList?.data?.result?.map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{i + 1 < 10 ? `00${i + 1}` : `0${i + 1}`}</td>
                                                    <td>{item?.serviceNo}</td>
                                                    <td>{item?.serviceName}</td>
                                                    <td>{item?.priceFor30Min}</td>
                                                    <td>{item?.priceFor45Min}</td>
                                                    <td>{item?.priceFor60Min}</td>
                                                    <td>--</td>
                                                    <td>--</td>
                                                    <td>
                                                        {item?.isActive ?
                                                            <span className="Green">Active</span> :
                                                            <span className="Red">Inactive</span>}
                                                    </td>
                                                    <td>
                                                        <div className="Actions">
                                                            <label className="switch">
                                                                <input
                                                                    type="checkbox"
                                                                    onClick={() => handleStatusShow(item, "service/status-update")}
                                                                    checked={item?.isActive}
                                                                />
                                                                <span className="slider" />
                                                            </label>
                                                            <Link className="Green" to="/service-management/edit-service" state={{ item, heading: "Edit New Service" }}>
                                                                <i className="fa fa-pencil" />
                                                            </Link>
                                                            <Link className="Blue" to="/service-management/service-details" state={{ item, heading: "Service Details" }}>
                                                                <i className="fa fa-eye" />
                                                            </Link>
                                                            <a
                                                                className="Red"
                                                                data-toggle="modal"
                                                                onClick={() => handleStatusShow(item, "service/delete-service")}
                                                            >
                                                                <i className="fa fa-trash" />
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        : !loader ?
                                            <tr>
                                                <td colSpan="10">
                                                    <p className='center'>No Data found.</p>
                                                </td>
                                            </tr>
                                            : ""}

                                </tbody>
                            </table>
                            {loader ? <p className="load">
                                <BeatLoader
                                    loading={loader}
                                    size={10}
                                    color='#fd701e'
                                />
                            </p> : ""}
                        </div>
                        {getServiceList?.data?.total > 0 && (
                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={10}
                                totalItemsCount={getServiceList?.data?.total}
                                pageRangeDisplayed={5}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                        )}
                    </div>
                </div>
                <div className="ModalBox">
                    <div id="AddNewServices" className="modal fade SmallNewModal" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="Category">
                                        <a
                                            href="javascript:void(0);"
                                            className="CloseModal"
                                            data-dismiss="modal"
                                        >
                                            ×
                                        </a>
                                        <h3>Add New Service</h3>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label>Service Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Service Name"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>Add Service Option</label>
                                                    <select className="form-control">
                                                        <option>add service option</option>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label>Price for 15 min</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Price for 15 min"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>Price for 30 min</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Price for 30 min"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>Price for 45 min</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Price for 45 min"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="Buttons">
                                            <button className="Button">Add Service</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        id="EditNewServices"
                        className="modal fade SmallNewModal"
                        role="dialog"
                    >
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="Category">
                                        <a
                                            href="javascript:void(0);"
                                            className="CloseModal"
                                            data-dismiss="modal"
                                        >
                                            ×
                                        </a>
                                        <h3>Edit Service</h3>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label>Service Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Service Name"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>Add Service Option</label>
                                                    <select className="form-control">
                                                        <option>add service option</option>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label>Price for 15 min</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Price for 15 min"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>Price for 30 min</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Price for 30 min"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>Price for 45 min</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Price for 45 min"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="Buttons">
                                            <button className="Button">Update</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div id="ServiceDelete" class="modal fade" role="dialog">
      <div class="modal-dialog">   
          <div class="modal-content"> 
              <div class="modal-body"> 
                  <div class="Decline">
                      <a href="javascript:void(0);" class="CloseModal" data-dismiss="modal">×</a>
                      <h3>Delete</h3>
                      <p>Are you sure you want to delete this Services ?</p>
                      <h4> 
                          <a href="javascript:void(0);" data-dismiss="modal">no</a>
                          <a href="javascript:void(0);" data-dismiss="modal">Yes</a>
                      </h4>
                  </div>
              </div> 
          </div>    
      </div>
  </div>  */}
                    <div
                        id="ServiceDelete"
                        className="modal fade ExtraSmallModal"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal Delete"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <div className="LogOutModalArea Delete">
                                        {/* <span><img src="images/logout-icon.png"></span> */}
                                        <h5>Delete Confirmation</h5>
                                        <p>Are you sure you want to delete this service ?</p>
                                        <div className="Buttons TwoButtons">
                                            <button type="button" className="Button" data-dismiss="modal">
                                                No
                                            </button>
                                            <button
                                                type="button"
                                                className="Button Red"
                                                data-dismiss="modal"
                                            >
                                                Yes
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

            <StatusModal iState={iState} updateState={updateState} type={type} />
        </>
    )
}

export default ServiceMgmt
