import React, { useEffect, useState } from 'react'
import SideNav from '../SideNav/SideNav'
import Header from '../Header/Header'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { userList } from '../../Redux/Slices/userMgmt'
import { BeatLoader } from 'react-spinners';
import Pagination from 'react-js-pagination';
import StatusModal from '../Modals/StatusModal';
import { toast } from 'react-toastify';
import { commomObj } from '../../Utils/util';


const initialState = {
    statusModal: false,
    id: '',
    status: '',
    type: '',
    search: '',
    fromDate: '',
    toDate: '',
    timeFrame: '',
    error: '',
    title: 'user'
}



const UserManagement = () => {


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [iState, updateState] = useState(initialState);
    const { id, type, search, fromDate, toDate, error, timeFrame } = iState;
    const { getUserList, loader } = useSelector((state) => state.userMgmt);
    const [activePage, updateActivePage] = useState(1);
    const location = useLocation();
    const heading = location?.state;


    const handlePageChange = (pageNumber) => {
        updateActivePage(pageNumber);
        const data = {
            page: pageNumber,
            search,
            startDate: fromDate,
            endDate: toDate,
            timeFrame

        }
        dispatch(userList(data))
    };


    const handleStatusShow = (item, type) => {
        if (type == "user/status-change") {
            updateState({ ...iState, statusModal: true, status: item?.isActive, id: item?._id, type })
        }
        else if (type == "user/delete-user") {
            updateState({ ...iState, statusModal: true, status: "delete", id: item?._id, type })
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        updateState({ ...iState, [name]: value, error: '' });
    }

    const handleRefresh = () => {
        updateState(initialState);
        dispatch(userList());
    }


    const handleFilterApply = () => {
        if (fromDate == '' && toDate !== '') {
            updateState({ ...iState, error: '*Please select From Date' })
        }
        else if (fromDate !== '' && toDate == '') {
            updateState({ ...iState, error: '*Please select To Date' })
        }
        else if (fromDate == '' && toDate == '' && search?.trim() == '') {
            updateState({ ...iState, error: '*Please select atleast one filter' })
        }
        else {
            const data = {
                search,
                startDate: fromDate,
                endDate: toDate,
            }
            dispatch(userList(data))
                .then(res => {
                    console.log("res", res)
                })
                .catch(err => {
                    console.log("err", err)
                })
        }
    }

    useEffect(() => {
        // if (timeFrame) {
        const data = {
            search,
            startDate: fromDate,
            endDate: toDate,
            timeFrame,
        }
        dispatch(userList(data));
        // }

    }, [timeFrame])


    useEffect(() => {
        dispatch(userList())
            .then(res => {
                console.log("res", res)
                if (res?.payload?.status == 401) {
                    toast.error("Invalid Token.Please Login again", commomObj);
                    navigate('/');
                }
            })
            .catch(err => {
                console.log("err", err)
            })
    }, [])








    console.log({ id })

    return (
        <div>
            <SideNav />
            <Header heading={heading} />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title"></h4>
                        <a className="TitleLink" href="javascript:void(0);">
                            <i className="fa fa-download" /> Download Excel
                        </a>
                    </div>
                    <div className="Small-Wrapper">
                        <div className="FilterArea">
                            <div className="FilterLeft">
                                <div className="form-group">
                                    <label>Search</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search here.."
                                        name='search'
                                        value={search}
                                        onChange={handleInputChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>From Date</label>
                                    <input type="date" name='fromDate' value={fromDate} onChange={handleInputChange} className="form-control" />

                                </div>
                                <div className="form-group">
                                    <label>To Date</label>
                                    <input type="date" name='toDate' value={toDate} onChange={handleInputChange} className="form-control" min={fromDate} />
                                </div>
                                <div className="form-group">
                                    <label>&nbsp;</label>
                                    <button className="Button" onClick={handleFilterApply}>Apply</button>

                                    <button className="Button Cancel" onClick={handleRefresh} style={{ marginLeft: "6px" }}>
                                        <i className="fa fa-refresh" />
                                    </button>
                                </div>
                            </div>
                            <div className="FilterRight">
                                <div className="form-group">
                                    <label>Timeframe</label>
                                    <select className="form-control" name='timeFrame' onClick={handleInputChange}>
                                        <option value='' selected={timeFrame == ""}>Select </option>
                                        <option value="Today">Today</option>
                                        <option value="Week">This Week</option>
                                        <option value="Month">This Month</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <span className='error'>{error}</span>
                    </div>
                    <div className="TableList">
                        <table>
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>User ID</th>
                                    <th>User name</th>
                                    <th>Contact Number</th>
                                    <th>Email ID</th>
                                    <th>City</th>
                                    <th>Registered On</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getUserList?.data?.result?.length > 0 ?
                                    getUserList?.data?.result?.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{i + 1 < 10 ? `00${i + 1}` : `0${i + 1}`}</td>
                                                <td>{item?.userNo}</td>
                                                <td>{item?.name}</td>
                                                <td>{item?.contact}</td>
                                                <td>{item?.email}</td>
                                                <td>{item?.city ? item?.city : '--'}</td>
                                                <td>{item?.createdAt?.split("T")?.at(0)}</td>
                                                <td>
                                                    {item?.isActive ?
                                                        <span className="Green">Active</span> :
                                                        <span className="Red">InActive</span>}

                                                </td>
                                                <td>
                                                    <div className="Actions">
                                                        <label className="switch">
                                                            <input type="checkbox"
                                                                checked={item?.isActive}
                                                                onClick={() => handleStatusShow(item, "user/status-change")}
                                                            />
                                                            <span className="slider" />
                                                        </label>
                                                        <Link className="Blue" to="/userDetail/basicPersonal" state={{ id: item._id, heading: "User Management" }}>
                                                            <i className="fa fa-eye" />
                                                        </Link>
                                                        <a
                                                            className="Red"
                                                            data-toggle="modal"
                                                            onClick={() => handleStatusShow(item, "user/delete-user")}
                                                        >
                                                            <i className="fa fa-trash" />
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    : !loader ?
                                        <tr>
                                            <td colSpan="10">
                                                <p className='center'>No Data found.</p>
                                            </td>
                                        </tr>
                                        : ""}

                            </tbody>
                        </table>
                        {loader ? <p className="load">
                            <BeatLoader
                                loading={loader}
                                size={10}
                                color='#fd701e'
                            />
                        </p> : ""}
                    </div>
                    <div className="pagination">
                        <ul >
                            {getUserList?.data?.total > 0 && (
                                <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={10}
                                    totalItemsCount={getUserList?.data?.total}
                                    pageRangeDisplayed={5}
                                    onChange={handlePageChange}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            )}
                        </ul>
                    </div>
                </div>
            </div>
            <StatusModal iState={iState} updateState={updateState} type={type} />
        </div>
    )
}

export default UserManagement